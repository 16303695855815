import React, { Fragment, useEffect } from "react";
import { Col, Row } from "antd";
import { WorkshopCommitmentItem } from "../../app/Components/Workshop/WorkshopCommitment/WorkshopCommitmentItem";
import { CkIcon } from "../../CkUI";

import "./style.css";
import { Helmet } from "react-helmet";
import { setCurrentSection } from "../../stores";
import { useDispatch } from "react-redux";

const commitments = [
  {
    title: "Ser transparente con el cliente",
    icon: <CkIcon name="hand-shake" />,
    list: [
      "Honestidad y claridad en los diagnósticos y servicios.",
      "Informar al cliente de alternativas e implicaciones potenciales.",
      "Obtener consentimiento antes de cualquier reparación.",
    ],
  },
  {
    title: "Enfoque en la calidad",
    icon: <CkIcon name="gear" />,
    list: [
      "Utilizar solo piezas y refacciones de calidad, tomar en cuenta el manual del fabricante.",
      "Dar garantía escrita por un periodo o kilometraje delimitado.",
      "Buscar adquirir nuevos aprendizajes y mantener capacitación constante.",
    ],
  },
  {
    title: "Precio justo",
    icon: <CkIcon name="hand-ok" />,
    list: [
      "Respetar las cotizaciones via CarKer.",
      "Precios claros por mano de obra, refacciones y otros servicios.",
    ],
  },
  {
    title: "Inclusión",
    icon: <CkIcon name="hands" />,
    list: [
      "Respetar a todos los clientes independientemente de su género, edad, sexo, preferencia, condición social, religión o cualquier otra razón.",
    ],
  },
  {
    title: "Respeto a compromisos",
    icon: <CkIcon name="hand-heart" />,
    list: [
      "Honestidad y claridad en diagnósticos y servicios.",
      "Informar al cliente de alternativas e implicaciones potenciales.",
      "Obtener consentimiento antes de cualquier reparación.",
    ],
  },
  {
    title: "",
    icon: undefined,
    list: [],
  },
];
export const WorkshopCommitmentsPage: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentSection("Compromisos del taller"));
  }, []);

  return (
    <Fragment>
      <Helmet>
        ‍<title>Usos De CarKer™ | CarKer™ Para Talleres</title>‍
        {/* <meta name="description" content="Qué es CarKer. Forma parte de nuestra comunidad selecta de talleres e impulsa tu negocio" data-react-helmet="true" />‍ */}
      </Helmet>
      <section className="page-container workshop-commitments-page">
        <h1>
          CarKer™ te acerca a miles de usuarios que buscan tus servicios.
        </h1>
        <Col>
          <Row justify="center">
            <p className="commitment-page-standard-text bold-text">
              Para formar parte de la red selecta de talleres CarKer™, cada
              taller debe cumplir con una serie de requisitos que demuestren la
              seguridad, calidad y confiabilidad de sus servicios
            </p>
          </Row>
          <Row justify="center">
            <p className="commitment-page-standard-text">
              Tras acreditar los procesos de evaluación y selección, los
              talleres afiliados pactan aceptar y seguir estrictamente los
              compromisos de CarKer para mantener siempre un servicio de
              excelencia.
            </p>
          </Row>
          <Row className="commitment-list-container" justify="center">
            {commitments.map((commitment, index) => {
              return (
                <Col lg={10} md={24}>
                  <WorkshopCommitmentItem
                    title={commitment.title}
                    commitmentList={commitment.list}
                    icon={commitment.icon}
                  />
                </Col>
              );
            })}
          </Row>
          <Row justify="center">
            <p className="commitment-page-large-text bold-text custom-width">
              Seguir estos compromisos te ayudará a que tus clientes sigan
              reconociéndote como un profesional confiable, comprometido y
              experto en el cuidado automotriz.
            </p>
          </Row>
          <Row justify="center">
            <p className="commitment-page-large-text cta custom-width-2">
              ¡Apégate a ellos y muestra el trabajo de excelencia que te
              distingue!
            </p>
          </Row>
          <Row justify="center">
            <p className="commitment-page-standard-text footer-text">
              El incumplimiento de alguno o algunos de los compromisos, podrá
              resultar en el retiro del taller de la plataforma CarKer. Esto con
              la finalidad de cuidar la reputación de todos los talleres que
              forman parte de este grupo selecto.
            </p>
          </Row>
        </Col>
      </section>
    </Fragment>
  );
};
