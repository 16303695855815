import React, {FC, Fragment} from "react";
import { Affix, Button, Col, Row } from "antd";
import { gtmAddLayer } from "../../../app/Utilities";

interface IProps {
  show: boolean;
  accept: Function;
  decline: Function;
}
export const TCButtons: FC<IProps> = (
  {
    show,
    accept,
    decline
  }
) => {

  return (
    <Fragment>
      {window.screen.width > 992 ? (
        <>
          <Col span={24} hidden={!show}>
            <Affix offsetBottom={0}>
              <div
                style={{
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Row
                  gutter={[18, 12]}
                  style={{
                    width: "100%",
                    paddingTop: "15px",
                    fontSize: "18px",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "0px",
                  }}
                >
                  <p>
                    {" "}
                    Acepto el Aviso de Privacidad y Términos y Condiciones
                  </p>
                </Row>
                <Row
                  style={{
                    margin: 0,
                    backgroundColor: "#F1F1F1",
                    paddingBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  gutter={[12, 12]}
                >
                  <Col
                    span={12}
                    style={{
                      textAlign: "right",
                      paddingLeft: "9px",
                      paddingRight: "9px",
                    }}
                  >
                    <Button
                      size="large"
                      className="button_red"
                      style={{
                        width: "35%",
                      }}
                      onClick={() => {
                        gtmAddLayer({
                          event: "click_terms_no_accepted",
                        });
                        decline();
                      }}
                    >
                      No acepto
                    </Button>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      textAlign: "left",
                      paddingLeft: "9px",
                      paddingRight: "9px",
                    }}
                  >
                    <Button
                      size="large"
                      className="button_blue"
                      style={{
                        width: "35%",
                      }}
                      onClick={() => {
                        gtmAddLayer({
                          event: "click_terms_accepted",
                        });
                        accept();
                      }}
                    >
                      Sí acepto
                    </Button>
                  </Col>
                </Row>
              </div>
            </Affix>
          </Col>
        </>
      ) : window.screen.width > 424 ? (
        <Col span={24} hidden={!show}>
          <Affix offsetBottom={0}>
            <div
              style={{
                backgroundColor: "#F1F1F1",
              }}
            >
              <Row
                gutter={[18, 12]}
                style={{
                  width: "100%",
                  paddingTop: "15px",
                  fontSize: "17px",
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "30px",
                }}
              >
                <p>
                  {" "}
                  Acepto el Aviso de Privacidad y Términos y Condiciones
                </p>
              </Row>
              <Row
                style={{
                  margin: 0,
                  backgroundColor: "#F1F1F1",
                  paddingBottom: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
                gutter={[12, 12]}
              >
                <Col
                  span={12}
                  style={{
                    textAlign: "right",
                    paddingLeft: "9px",
                    paddingRight: "9px",
                  }}
                >
                  <Button
                    size="large"
                    className="button_red"
                    style={{
                      width: "70%",
                    }}
                    onClick={() => {
                      gtmAddLayer({
                        event: "click_terms_no_accepted",
                      });
                      decline();
                    }}
                  >
                    No acepto
                  </Button>
                </Col>
                <Col
                  span={12}
                  style={{
                    textAlign: "left",
                    paddingLeft: "9px",
                    paddingRight: "9px",
                  }}
                >
                  <Button
                    size="large"
                    className="button_blue"
                    style={{
                      width: "70%",
                    }}
                    onClick={() => {
                      gtmAddLayer({
                        event: "click_terms_accepted",
                      });
                      accept();
                    }}
                  >
                    Sí acepto
                  </Button>
                </Col>
              </Row>
            </div>
          </Affix>
        </Col>
      ) : (
        <Col span={24} hidden={!show}>
          <Affix offsetBottom={0}>
            <div
              style={{
                backgroundColor: "#FAFAFA",
              }}
            >
              <Row
                gutter={[18, 12]}
                style={{
                  width: "100%",
                  paddingTop: "15px",
                  fontSize: "16px",
                  margin: 0,
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  paddingLeft: "5px",
                }}
              >
                <p>
                  {" "}
                  Acepto el Aviso de Privacidad y Términos y Condiciones
                </p>
              </Row>
              <Row
                style={{
                  margin: 0,
                  backgroundColor: "#FAFAFA",
                  paddingBottom: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
                gutter={[12, 12]}
              >
                <Col
                  span={12}
                  style={{
                    textAlign: "right",
                    paddingLeft: "9px",
                    paddingRight: "9px",
                  }}
                >
                  <Button
                    size="large"
                    className="button_red"
                    style={{
                      width: "90%",
                    }}
                    onClick={() => {
                      gtmAddLayer({
                        event: "click_terms_no_accepted",
                      });
                      decline();
                    }}
                  >
                    No acepto
                  </Button>
                </Col>
                <Col
                  span={12}
                  style={{
                    textAlign: "left",
                    paddingLeft: "9px",
                    paddingRight: "9px",
                  }}
                >
                  <Button
                    size="large"
                    className="button_blue"
                    style={{
                      width: "90%",
                    }}
                    onClick={() => {
                      gtmAddLayer({
                        event: "click_terms_accepted",
                      });
                      accept();
                    }}
                  >
                    Sí acepto
                  </Button>
                </Col>
              </Row>
            </div>
          </Affix>
        </Col>
      )
      }
    </Fragment>
  )
}
