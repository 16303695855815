import React, { useRef, useEffect, memo, useState } from "react";
import { Row, Col } from "antd";
import { TermsAndConditionsContent } from "./TermsAndConditionsContent";
import { TCButtons } from "./TCButtons";
import { CkIcon } from "../../CkUI";

interface IProps {
  accept: Function;
  decline: Function;
  showButtons?: boolean;
}

const TermsAndConditionsPage: React.FC<IProps> = ({ accept, decline, showButtons }: IProps) => {
  const [showBackButton, setShowBackButton] = useState<boolean>(true);
  const termsRef = useRef() as any;
  const scrollToRef = (ref: any) => {
    ref.current.scrollTop = 0;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    scrollToRef(termsRef);
  }, []);

  const openAppLink = (url: string) => {
    let win = window.open(url, "_blank");
    if (win) {
      win.focus();
    }
  };

  return (
    <>
      {/*
      <div
        style={{
          width: "50%",
          display: "inline-block",
          float: "left",
          background: `url(${Background})`,
          height: "100%",
          position: "relative",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          clipPath: "polygon(0 0, 100% 0%, 80% 100%, 0% 100%)",
        }}
        className="web"
      >
        <div
          style={{
            position: "absolute",
            top: "48.5%",
            marginTop: "-20%",
            textAlign: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              textAlign: "left",
              margin: "0 0 5% 10%",
            }}
          >
            <h1
              style={{
                color: "white",
                margin: "0 auto",
                marginBottom: "5%",
                fontSize: setFontSize(48.8),
                fontFamily: "Emprint-semibold",
              }}
            >
              Bienvenido
            </h1>

            <h3
              style={{
                color: "white",
                fontSize: setFontSize(22),
                fontFamily: "Emprint-semibold",
                marginTop: "10%",
              }}
            >
              <p
                style={{ marginBottom: "10px", fontFamily: "Emprint-semibold" }}
              >
                Forma parte de esta plataforma digital que te ayudará
              </p>
              <p style={{ fontFamily: "Emprint-semibold" }}>
                a transformar e impulsar tu negocio.
              </p>
            </h3>
          </div>
          <div
            style={{
              fontSize: setFontSize(22),
              color: "white",
              marginRight: "49%",
              marginBottom: "-10%",
              marginTop: "14%",
              fontFamily: "Emprint-semibold",
            }}
          >
            Descarga la app desde tu celular
          </div>
          <div
            style={{
              display: "grid",
              gridGap: "2vh",
              width: "25vw",
              marginLeft: "10%",
              marginTop: "15%",
            }}
          >
            <Button
              style={{
                border: "1px solid white",
                backgroundColor: "#ddd0",
                color: "white",
                height: "7vh",
                transform: "skew(-20deg)",
              }}
              onClick={openAppLink.bind(
                null,
                "https://apps.apple.com/us/app/carker/id1549021610"
              )}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    style={{ marginLeft: "75%" }}
                    src={IconAppleStore}
                    alt="apple store logo"
                  />
                </div>
                <div
                  style={{
                    marginLeft: "32%",
                    marginTop: "2%",
                    fontSize: setFontSize(16),
                    fontFamily: "Emprint-regular",
                    transform: "skew(20deg)",
                  }}
                >
                  Apple Store
                </div>
              </div>
            </Button>
            <Button
              style={{
                border: "1px solid white",
                backgroundColor: "#ddd0",
                color: "white",
                height: "7vh",
                transform: "skew(-20deg)",
              }}
              onClick={openAppLink.bind(
                null,
                "https://play.google.com/store/apps/details?id=com.exxonmobil.bcx.workshop"
              )}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    style={{ marginLeft: "75%", fontFamily: "Emprint-regular" }}
                    src={IconPlayStore}
                    alt="google play logo"
                  />
                </div>
                <div
                  style={{
                    marginLeft: "32%",
                    marginTop: "2%",
                    fontSize: setFontSize(16),
                    fontFamily: "Emprint-regular",
                    transform: "skew(20deg)",
                  }}
                >
                  Play Store
                </div>
              </div>
            </Button>
          </div>
        </div>
      </div>
      */ }
      <Row gutter={[48, 48]} style={{ margin: 0 }}>
        <Col span={2} >
          {
            showBackButton && (
              <div style={{padding: "40% 3% 3% 3%", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                <CkIcon name="caret-left-large" onClick={decline} style={{cursor: "pointer"}}/>
              </div>
            )
          }
        </Col>
        <Col span={22} ref={termsRef}>
          <TermsAndConditionsContent onDataRetentionClick={() => setShowBackButton(!showBackButton)} />
          <TCButtons show={showButtons} accept={accept} decline={decline}/>
        </Col>
      </Row>
      <br />
    </>
  );
};

export default memo(TermsAndConditionsPage);
