import React, { FC, memo, useEffect, useState } from "react";
import {
  getWorkshopFormData,
  getWorkshopFormOriginalData,
  getWorkshopTypes,
  setCurrentSection,
} from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import { WorkshopType } from "shared";
import WorkshopForm from "../../app/Components/WorkshopForm";

interface IProps {}

const WorkshopFormPage: FC<IProps> = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isNewAccount, setIsNewAccount] = useState<boolean>(false);

  const workshopData = useSelector(getWorkshopFormData);
  const workshopFormOriginalData = useSelector(getWorkshopFormOriginalData);
  const workshopTypes: WorkshopType[] = useSelector(getWorkshopTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentSection("Agregar taller"));
  }, []);

  useEffect(() => {
    if (window.location && window.location.pathname.includes("cuenta")) {
      setIsNewAccount(true);
    }
  }, [window]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Determina si el taller esta siendo editado o si se esta creando uno nuevo
  useEffect(() => {
    if (workshopFormOriginalData.id === "") {
      setIsEditing(false);
    }
  }, [workshopData]);

  return (
    <div
      className="page-container"
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <div
        style={{
          padding: "15px max(3%, 15px) 0px",
          maxWidth: "1054px",
          width: "100%",
        }}
      >
        <WorkshopForm workshopData={workshopData} isNewAccount={isNewAccount} />
      </div>
    </div>
  );
};

export default memo(WorkshopFormPage);
