import { Collapse, Divider } from "antd";
import React, {FC, Fragment, useState} from "react";
const { Panel } = Collapse;

import "./styles.css";
import { CkIcon, gtmSetId } from "../../../CkUI";
import DataDeletionNotice from "../../../app/Components/DataDeletionNotice";
import { useWindowSize } from "../../../app/Utilities";
interface IProps {
  onDataRetentionClick?: Function;
}
const handleLinkClick = (ev) => {
  console.info("ev", ev);
  console.info("ev.currentTarget", ev.currentTarget);
  gtmSetId(ev.currentTarget);
}
export const TermsAndConditionsContent: FC<IProps> = (
  {
    onDataRetentionClick,
  }) => {
  const [showCancelDataRetentionNotice, setShowCancelDataRetentionNotice] = useState(false);
  const windowSize = useWindowSize();

  return (
    <div className="tc-content">
      {
        showCancelDataRetentionNotice ? (
          <Fragment>
            <div
              className="dataDeletionContainer"
              style={{marginTop: "5%", width: window.screen.width > 992 ? "68%" : "100%", margin: "auto"}}
            >
              <CkIcon
                name="caret-left"
                width={20}
                height={20}
                fill={""}
                onClick={() => {
                  setShowCancelDataRetentionNotice(false);
                  onDataRetentionClick();
                }}
              />
              <DataDeletionNotice title="Eliminación de datos" fontSize={windowSize.width > 992 ? 20 : 16}/>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <p className="tc-title">
              TÉRMINOS Y CONDICIONES
            </p>

            <p className="bold tc-subtitle">
              Plataforma de mantenimiento de automóviles: Acuerdo de términos de uso
            </p>

            <div style={{display: "flex", flexDirection: "row", paddingBottom: "25px"}}>
              <div className="gray-background" style={{marginRight: "15px"}}>
                <p className="tc-extra-text">
                  Fecha de la última revisión: <span className="bold">28 de enero de 2021</span>
                </p>
              </div>

              <div className="gray-background">
                <p className="tc-extra-text">
                  Versión del acuerdo No.: <span className="bold">1.0</span>
                </p>
              </div>
            </div>

            <Collapse
              expandIcon={() => (<CkIcon name="panel-expand" fill="#D5000A"/>)}
              ghost
              expandIconPosition="end"
              className="main-tc-collapse"
              defaultActiveKey={[7, 9]}
            >
              <Panel header="Introducción" key={1}>
                <p>
                  Nada en este documento pretende anular la separación corporativa de las entidades locales.
                </p>

                <p>
                  ExxonMobil México, S.A. de C.V. y sus sociedades filiales ("<span className="bold">ExxonMobil</span>"
                  o
                  "<span className="bold">nosotros</span>") están dispuestas a otorgarle a usted (también referido en
                  primera
                  persona) acceso a las aplicaciones móviles o en línea de la plataforma de mantenimiento de automóviles
                  (las <span className="bold">"aplicaciones"</span>) junto con otros sitios, aplicaciones, aplicaciones
                  en línea, servicios, interfaces,
                  herramientas, software y otras funciones web relacionadas o vinculadas que sean proporcionadas en o a
                  través
                  de las aplicaciones (conjuntamente, la "<span className="bold">aplicación CarKer</span>") sujeto a su
                  consentimiento de cumplir con estos Términos de Uso (el "<span className="bold">acuerdo</span>").
                </p>
              </Panel>
              <Divider/>

              <div className="light-blue-background" style={{padding: "15px", margin: "25px 0 15px"}}>
                <p>
                  Al utilizar la aplicación CarKer, usted acepta cada uno de los términos establecidos en el presente.
                  Si no
                  acepta algún término o condición establecido en el presente o no está de acuerdo en cumplir con algún
                  término o condición establecido en el presente, no utilice la aplicación CarKer.
                </p>

                <p className="bold" style={{margin: 0}}>
                  Al utilizar la aplicación CarKer, usted en este acto acuerda y declara lo siguiente:
                </p>
              </div>

              <Panel header={<><span className="tc-item-number">1.</span> ExxonMobil puede modificar este acuerdo</>}
                     key={2}>
                <p>ExxonMobil se reserva el derecho, a su sola discreción, a cambiar, modificar, agregar o eliminar
                  partes de
                  este acuerdo en cualquier momento. Cualquier nueva característica o contenido o aplicaciones
                  actualizadas
                  que ponemos a su disposición como parte de la aplicación CarKer estarán sujetos a este acuerdo. Su
                  acceso o
                  uso continuo de la aplicación CarKer después de dichos cambios constituye su aceptación del nuevo
                  acuerdo.</p>
              </Panel>
              <Divider/>

              <Panel key={3} header={<><span className="tc-item-number">2.</span> Este acuerdo es válido hasta su
                terminación</>}>
                <p>
                  Este acuerdo es válido hasta su terminación. Usted puede dar por terminado este acuerdo por cualquier
                  motivo
                  desinstalando la aplicación CarKer. Sus derechos bajo este acuerdo terminarán automáticamente, sin
                  notificación por parte de ExxonMobil, si usted no cumple con cualquiera de los términos, condiciones o
                  lineamientos de este acuerdo. A la terminación de este acuerdo, usted dejará de usar la aplicación
                  CarKer.
                  En caso de que sus derechos de uso de la aplicación CarKer sean dados por terminados, usted perderá
                  inmediatamente el acceso a cualquier información que pueda estar contenida en los sistemas de
                  ExxonMobil o
                  de sus licenciantes. Nosotros podemos dar por terminado su acceso a la aplicación CarKer en cualquier
                  momento. También podemos eliminar cualquier contenido de usuario que publique sin justificación y sin
                  notificación alguna.
                </p>
              </Panel>
              <Divider/>

              <Panel key={4}
                     header={<><span className="tc-item-number">3.</span> ExxonMobil podrá modificar la aplicación
                       CarKer</>}>
                <p>
                  Actualmente la aplicación CarKer está disponible para usted en forma gratuita. ExxonMobil se reserva
                  el
                  derecho, a su sola discreción, sin notificación alguna, en cualquier momento y de tiempo en tiempo,
                  temporal
                  o permanentemente, en todo o en parte, a modificar, suspender, terminar, retirar o interrumpir toda o
                  cualquier parte de la aplicación CarKer sin compensación en su favor. Usted acepta que ni ExxonMobil
                  ni
                  alguna de nuestras filiales será responsable frente usted o algún tercero por cualquier modificación,
                  suspensión o interrupción de todo o cualquier parte de la aplicación CarKer o de cualquier servicio,
                  contenido o característica ofrecida a través de la aplicación CarKer.
                </p>
              </Panel>
              <Divider/>

              <Panel key={5} header={<><span className="tc-item-number">4.</span> La aplicación CarKer pertenece a
                ExxonMobil</>}>
                <p>
                  La aplicación CarKer (incluyendo sin limitar cualquier material, software, código, archivo, contenido
                  e
                  imágenes contenidas o generadas por la aplicación CarKer, datos de acompañamiento, y otro software
                  integrado, incluyendo software de terceros (conjuntamente, el "<span
                  className="bold">contenido ExxonMobil</span>")), las actualizaciones y mejoras de la aplicación
                  CarKer, y la
                  documentación y fuentes de acompañamiento (conjuntamente, la "<span className="bold">documentación ExxonMobil</span>")
                  — ya sea en memoria para sólo lectura, en cualquier otro medio o en cualquier otra forma — son
                  propiedad de
                  ExxonMobil o sus licenciantes y están protegidos por la ley aplicable, incluyendo leyes con respecto a
                  derechos de autor, marcas, patentes y/o secretos comerciales. Ni la propiedad ni algún derecho de
                  propiedad
                  intelectual son transferidos a usted, sino que permanecen como propiedad de ExxonMobil o del tercero
                  correspondiente, que tenga propiedad plena y completa sobre la aplicación CarKer, el contenido
                  ExxonMobil,
                  las actualizaciones y mejoras de la aplicación CarKer y la documentación ExxonMobil.
                </p>
                <p>
                  Adicionalmente, ESSO, EXXON, EXXONMOBIL, MOBIL, MOTORPASS, SPEEDPASS y ciertas otras marcas, marcas
                  comerciales y marcas de servicios (conjuntamente, las "<span className="bold">marcas ExxonMobil</span>")
                  son
                  marcas de ExxonMobil y sus filiales. No todas las marcas de ExxonMobil aparecerán en la aplicación
                  CarKer.
                  Las marcas de terceros pueden aparecer en la aplicación CarKer al referirse a dichas entidades o a sus
                  productos o servicios. Las marcas de terceros que se muestran en la aplicación CarKer son propiedad de
                  sus
                  respectivos propietarios.
                </p>
                <p>
                  El contenido ExxonMobil y la documentación ExxonMobil están protegidos por derechos de autor. Todos
                  estos
                  derechos están reservados. Todos los contenidos, interfaces, gráficos, código, derechos de diseño y
                  otros
                  derechos de propiedad intelectual, en cada caso, ya sea que estén registrados o no, distintos de las
                  marcas
                  de terceros, y el fondo de comercio relacionado son propiedad de ExxonMobil y todo uso será en
                  beneficio de
                  ExxonMobil.
                </p>
                <p>
                  Usted entiende que no tiene derechos sobre la aplicación CarKer, el contenido ExxonMobil, la
                  documentacion
                  ExxonMobil, las marcas ExxonMobil o cualquier otra propiedad de ExxonMobil excepto como se indica en
                  este
                  acuerdo. ExxonMobil dará por terminado su acceso a, o uso de, la totalidad o cualquier parte de la
                  aplicación CarKer, sin notificación alguna, si usted viola o se apropia indebidamente de los derechos
                  de
                  propiedad intelectual, incluyendo los derechos de autor, de ExxonMobil u otros. además, ExxonMobil se
                  reserva todos los demás derechos con respecto a su propiedad intelectual.
                </p>
              </Panel>
              <Divider/>

              <Panel key={6} header={<><span className="tc-item-number">5.</span> Derecho de uso limitado</>}>
                <p>
                  Únicamente para permitir el uso de la aplicación CarKer, sujeto a los términos de terceros (como se
                  describe
                  más adelante), ExxonMobil otorga en este acto una licencia limitada, revocable y no trasferible para
                  usar la
                  aplicación CarKer según lo dispuesto en este acuerdo y para ver y mostrar el contenido ExxonMobil
                  (excepto
                  el código fuente del software de ExxonMobil o de sus licenciantes), la documentación ExxonMobil y el
                  contenido de usuario (como se define más adelante) para su uso personal, pero únicamente en relación
                  con su
                  uso de la aplicación CarKer. Esta licencia le otorga el derecho a instalar la aplicación CarKer en su
                  dispositivo móvil y a usar la aplicación CarKer sujeto a este acuerdo; sin embargo, no podrá poner a
                  disposición la aplicación CarKer en una red en la que pudiera utilizar múltiples dispositivos al mismo
                  tiempo. Además, este acuerdo no le otorga derecho alguno a utilizar las interfaces propiedad de
                  ExxonMobil y
                  otra propiedad intelectual en el diseño, desarrollo, fabricación, licenciamiento o distribución de
                  dispositivos y accesorios de terceros para uso con la aplicación CarKer. Queda prohibido cualquier uso
                  de la
                  aplicación CarKer en cualquier manera no permitida bajo este acuerdo, incluyendo sin limitar la
                  re-venta,
                  transferencia, modificación o distribución de la aplicación CarKer o la copia o distribución de texto,
                  imágenes, música, video, datos, hipervínculos, pantallas y otros contenidos proporcionados por la
                  aplicación
                  CarKer. Adicionalmente, este acuerdo no le otorga el derecho a recibir, y no obliga a ExxonMobil a
                  proporcionarle, la documentación ExxonMobil, soporte, asistencia telefónica, o mejoras o
                  actualizaciones de
                  la aplicación CarKer.
                </p>
                <p>
                  Excepto por esta licencia limitada de uso personal, cuando utilice la aplicación CarKer no recibe una
                  licencia ni algún otro derecho de ExxonMobil, incluidos derechos de propiedad intelectual u otros
                  propiedad
                  de ExxonMobil. Por lo tanto, salvo como se indica anteriormente, usted no podrá copiar, alterar,
                  modificar,
                  publicar, emitir, transmitir, ejecutar, distribuir, mostrar, utilizar, crear trabajos derivados de, o
                  vender
                  la aplicación CarKer, el contenido ExxonMobil, el contenido de usuario o la documentación ExxonMobil.
                </p>
              </Panel>
              <Divider/>

              <div className="ant-collapse-item ant-collapse-no-arrow panel-like-container">
                <div className="ant-collapse-header">
                  <p className="ant-collapse-header-text" style={{margin: 0}}>
                    <span className="tc-item-number">6.</span> No usaré la aplicación CarKer a menos que acepte y siga
                    cada una de estas reglas:
                  </p>
                </div>

                <Collapse
                  expandIcon={() => (<CkIcon name="panel-expand" fill="#D5000A"/>)}
                  ghost
                  expandIconPosition="end"
                  className="secondary-tc-collapse"
                >
                  <Panel key={10} header="A. Uso seguro de la aplicación CarKer">
                    <p>
                      La seguridad de todos los usuarios de la aplicación CarKer es importante. Adicionalmente,
                      ExxonMobil
                      cree que todos los usuarios y visitantes se benefician de las reglas básicas de conducta al
                      utilizar la
                      aplicación CarKer. El uso y goce generalizado de la aplicación CarKer se pone en peligro cuando
                      pocas
                      personas actúan irresponsablemente. Para ayudar a todos a tener una experiencia positiva, le
                      solicitamos
                      que siga algunas reglas básicas de conducta al utilizar la Aplicación CarKer. En consecuencia,
                      usted
                      acepta lo siguiente:
                    </p>
                    <ol>
                      <li>
                        Usaré la aplicación CarKer únicamente de manera segura y No voy a utilizar la aplicación CarKer
                        mientras conduzco u opero un vehículo automotor.
                      </li>
                      <li>
                        Tendré cuidado, prudencia y el juicio adecuado al utilizar la aplicación CarKer.
                      </li>
                      <li>
                        No alentaré ni facilitaré ninguna violación de este acuerdo.
                      </li>
                      <li>
                        No usaré la aplicación CarKer a efecto de promover cualquier material ilegal, dañino, de acoso,
                        difamatorio, amenazante, vulgar, indecente, sexualmente explícito, que promueva el odio o en
                        cualquier
                        manera inaceptable de cualquier tipo, cualquier material que explote niños o sea invasivo de la
                        privacidad de otra persona u otros derechos, o cualquier material que ExxonMobil a su sola
                        discreción
                        no desee publicar o transmitir en la aplicación CarKer.
                      </li>
                      <li>
                        No enviaré imágenes, software, texto u otro material protegido por las leyes de propiedad
                        intelectual,
                        incluyendo sin limitar las leyes de derechos de autor o marcas y los derechos de publicidad y
                        privacidad, a menos que posea o controle los derechos sobre los mismos o haya recibido todas las
                        autorizaciones necesarias para realizarlo.
                      </li>
                      <li>
                        No participaré en y/o alentaré conductas que a) violen cualquier ley o regulación aplicable o b)
                        den
                        lugar a responsabilidad civil.
                      </li>
                      <li>
                        No me haré pasar por ninguna persona o entidad, declararé falsamente o de otra manera
                        tergiversaré mi
                        identidad o afiliación en manera alguna
                      </li>
                      <li>
                        No participaré en la comercialización o promoción de bienes o servicios que no sean bienes o
                        servicios
                        de ExxonMobil en relación con mi compromiso con ExxonMobil.
                      </li>
                      <li>
                        No apoyaré ni permitiré que alguna persona participe en alguna de las actividades descritas
                        anteriormente.
                      </li>
                    </ol>

                    <p>
                      Nos reservamos el derecho (pero no la obligación), a nuestra sola discreción, de rechazar,
                      eliminar,
                      suspender o terminar - sin notificación alguna - su acceso a o uso de la aplicación CarKer cuando
                      ocurra
                      un incumplimiento o intento de incumplimiento de este Acuerdo, incluyendo sin limitar cualquiera
                      de las
                      reglas indicadas anteriormente. Los incumplimientos indirectos o intentos de incumplimiento de
                      este
                      acuerdo, y los incumplimientos reales o intentos de los mismos por parte de un tercero que actúe
                      en su
                      nombre, se considerarán violaciones de su parte a este acuerdo. Además, es un incumplimiento de
                      este
                      acuerdo el utilizar los servicios de otra sociedad con el propósito de facilitar cualquiera de las
                      actividades que violen este acuerdo si puede esperarse razonablemente que el uso del servicio de
                      otra
                      sociedad afectará negativamente la aplicación CarKer en cualquier manera.
                    </p>
                  </Panel>

                  <Panel key={11} header="B. Servicios de datos de dispositivos móviles">
                    <p>
                      La aplicación CarKer puede requerir el uso de Internet para funcionar correctamente, por lo que la
                      calidad y disponibilidad de la aplicación CarKer puede verse afectada por factores fuera del
                      control de
                      ExxonMobil. ExxonMobil no se hace responsable de la falta de disponibilidad de la aplicación
                      CarKer.
                      Además, los términos de su acuerdo con su respectivo proveedor de red móvil se aplican al utilizar
                      la
                      aplicación CarKer. Es posible que su proveedor de telefonía móvil aplique cargos por el acceso a
                      los
                      servicios de conexión de red por el tiempo de conexión al acceder a la aplicación CarKer. Todos
                      estos
                      cargos son su responsabilidad. Además, usted acepta que el uso que usted realice de la aplicación
                      CarKer
                      no viola los términos o condiciones de la cobertura otorgada a usted por su respectivo proveedor
                      de red
                      móvil.
                    </p>
                  </Panel>

                  <Panel key={12} header="C. Publicación de contenido de usuario">
                    <p>
                      La aplicación CarKer puede permitirle ingresar, publicar, presentar o enlazar a cierto contenido
                      (incluyendo, por ejemplo, su nombre de usuario, su dirección de correo electrónico, su contraseña,
                      texto, datos, software, fotografías, imágenes, gráficos, archivos, videos, audio, sonido, música,
                      mensajes, materiales, obras de autoría, etc.) y proporcionar ciertos comentarios, calificaciones y
                      reseñas que puedan visualizar otras personas (conjuntamente, el "contenido de usuario").
                      ExxonMobil no
                      reclama ningún derecho de propiedad sobre algún contenido de usuario que usted ingrese, publique,
                      presente, proporcione o vincule a o mediante la aplicación CarKer. Sin embargo, al ingresar,
                      publicar,
                      presentar, proporcionar o vincular al contenido de usuario en o a través de la aplicación CarKer,
                      usted
                      otorga a ExxonMobil y ExxonMobil en este acto acepta una licencia global, perpetua, irrevocable,
                      no
                      exclusiva, transferible, objeto de licencia, totalmente pagada y libre de regalías para usar,
                      vender,
                      reproducir, crear trabajos derivados de, combinar con otras obras, alterar, modificar, eliminar
                      de,
                      agregar a, traducir, distribuir copias, mostrar, ejecutar y publicar el Contenido de Usuario y su
                      nombre
                      en relación con dicho uso de su contenido de usuario en todo y cualquier medio y en cualquier
                      manera, en
                      todo o en parte, sin restricción alguna o responsabilidades a su cargo. Adicionalmente, al
                      ingresar,
                      publicar, presentar, proporcionar o vincular al Contenido de Usuario, usted en este acto libera a
                      ExxonMobil de cualquier reclamación de que dicho uso, según lo autorizado anteriormente, viola
                      cualquier
                      derecho que usted pueda tener bajo la ley aplicable, y usted entiende que no tendrá derecho a
                      compensación alguna por cualquier uso de su contenido de usuario. Además, al ingresar, publicar,
                      presentar, proporcionar o vincular al contenido de usuario, usted en este acto declara, garantiza
                      y
                      acepta lo siguiente:
                    </p>
                    <ol>
                      <li>
                        Poseo y controlo todos los derechos sobre el contenido de usuario o de cualquier otra manera
                        cuento
                        con (a) los derechos legales para ingresar, publicar, presentar, proporcionar o vincular al
                        contenido
                        de usuario utilizando la aplicación CarKer, y (b) el derecho a otorgar a ExxonMobil la licencia
                        especificada en este Acuerdo.
                      </li>
                      <li>
                        El contenido de usuario que ingrese, publique, presente, proporcione o vincule no resulta ni
                        resultará
                        en un incumplimiento de un contrato que haya celebrado con cualquier otra persona.
                      </li>
                      <li>
                        Pagaré todas las regalías, tarifas y cualquier otra cantidad debida a cualquier persona, por
                        cualquier
                        contenido de usuario que ingrese, publique, presente, proporcione o vincule al uso de la
                        aplicación
                        CarKer.
                      </li>
                      <li>
                        Ninguno de los contenidos de usuario está sujeto a obligaciones de confidencialidad.
                      </li>
                      <li>
                        El contenido de usuario que ingrese, publique, presente, proporcione o vincule no viola los
                        derechos
                        de privacidad, derechos de publicidad, derechos de autor, derechos contractuales, derechos de
                        propiedad intelectual o cualquier otro derecho de cualquier persona.
                      </li>
                      <li>
                        El contenido del usuario es veraz, exacto, correcto y se ofrece de buena fe.
                      </li>
                      <li>
                        Soy el único responsable por todo el contenido de usuario que ingrese, publique, presente,
                        proporcione, vincule o ponga a disposición de cualquier manera en o a través de la aplicación
                        CarKer.
                      </li>
                      <li>
                        No publicaré los documentos de identificación sensibles, información financiera u otros datos
                        personales de otra persona sin el permiso de esa persona.
                      </li>
                      <li>
                        No ingresaré, publicaré, presentaré, proporcionaré o enlazaré contenido de usuario que sea
                        obsceno o
                        pueda ser promover el odio o sea ofensivo por motivos raciales, étnicos, sexuales o de cualquier
                        otro
                        tipo, que sea dañino, vulgar o desagradable, o sea difamatorio, calumnioso o invada la
                        privacidad de
                        otra persona.
                      </li>
                      <li>
                        No usaré la propiedad intelectual de ExxonMobil (incluyendo, por ejemplo, ESSO, EXXON,
                        EXXONMOBIL,
                        MOBIL, SPEEDPASS, y ciertas otras marcas, marcas registradas y marcas de servicios que sean
                        propiedad
                        de ExxonMobil) sin el permiso de ExxonMobil.
                      </li>
                      <li>
                        Entiendo que ExxonMobil puede, a su sola discreción, monitorear el contenido de usuario que yo y
                        otros
                        usuarios ingresemos, publiquemos, presentemos, proporcionemos o vinculemos utilizando la
                        aplicación
                        CarKer.
                      </li>
                      <li>
                        Entiendo que ExxonMobil puede, sin notificación y a su sola discreción, eliminar cualquier
                        contenido
                        de usuario que consideremos obsceno, ofensivo, calumnioso, difamatorio o viole la ley o
                        cualquiera de
                        los términos y condiciones de este Acuerdo. Adicionalmente, entiendo que ExxonMobil puede, a su
                        sola
                        discreción, eliminar cualquier Contenido de Usuario si creemos que esto mejorará la aplicación
                        CarKer.
                      </li>
                      <li>
                        Entiendo que ExxonMobil se reserva el derecho a exigir la pérdida del nombre de usuario de
                        cualquier
                        cuenta que se vuelva inactiva o cualquier nombre de usuario que viole la marca comercial u otros
                        derechos de propiedad intelectual de cualquier otra persona.
                      </li>
                      <li>
                        Entiendo que ExxonMobil puede suspender o dar por terminado mi uso de la aplicación CarKer si
                        incumplo
                        las reglas de este acuerdo.
                      </li>
                    </ol>
                    <p>
                      <span className="bold">AVISO</span>: ExxonMobil no tiene obligación alguna de responder a ninguno
                      de sus
                      contenidos de usuario. Adicionalmente, ExxonMobil no es responsable por ningún contenido de
                      usuario,
                      incluyendo la veracidad, integridad, objetividad o utilidad de dicho contenido de usuario.
                      ExxonMobil no
                      aprueba ningún contenido de usuario, no ha verificado la identidad de las personas que utilizan la
                      aplicación CarKer, y no filtra, supervisa, edita o revisa el contenido de usuario antes de que sea
                      accesible en o a través de la aplicación CarKer. En consecuencia, usted puede estar expuesto a
                      contenido
                      de otros usuarios que sea ofensivo u objetable.
                    </p>
                  </Panel>
                </Collapse>
              </div>
              <Divider/>

              <Panel
                key={8}
                header={<><span className="tc-item-number">7.</span> La aplicación CarKer contiene componentes de
                  software de terceros que se rigen por términos adicionales</>}>
                <p>
                  La aplicación CarKer puede contener varios componentes y servicios de software de terceros (que pueden
                  incluir varios módulos por licencia, modelos, etc.). Cuando usted utilice la aplicación CarKer, acepta
                  el
                  uso por parte de ExxonMobil de dichos componentes y servicios de software de terceros. Usted también
                  acepta
                  cualquier término de uso, licencias o políticas de privacidad aplicables relacionados con dichos
                  componentes
                  y servicios de software de terceros, si los hay. El incumplimiento de cualquiera de dichos términos de
                  uso,
                  licencias o políticas de privacidad se considerará un incumplimiento de este acuerdo. Al utilizar la
                  aplicación CarKer, usted libera expresamente a ExxonMobil de toda y cualquier responsabilidad derivada
                  del
                  uso que usted haga de cualquier componente o servicio de terceros integrado a la aplicación CarKer.
                </p>
                <p>
                  La aplicación CarKer también podrá contener enlaces a otros sitios, solicitudes o contenido, que
                  pueden
                  aparecer integrados en la aplicación CarKer, pero que no forman parte de la aplicación CarKer
                  ("enlaces de
                  terceros"). Su uso de enlaces de terceros está sujeto a los términos de uso, si los hay, que rigen el
                  uso de
                  dichos enlaces de terceros. ExxonMobil no puede controlar estos enlaces de terceros y no asume
                  responsabilidad alguna por su objeto, términos, políticas de privacidad o prácticas. Si existe algún
                  conflicto entre este acuerdo y cualquier término o aviso establecido con respecto a los enlaces de
                  terceros,
                  entonces los términos de dichos enlaces de terceros prevalecerán. Por favor revise los términos de uso
                  de
                  todos estos enlaces de terceros para que entienda todos los términos que le son aplicables. Al
                  utilizar la
                  aplicación CarKer, usted libera expresamente a ExxonMobil de toda y cualquier responsabilidad derivada
                  del
                  uso que realice de cualquier enlace de terceros.
                </p>
              </Panel>
              <Divider/>

              <div className="ant-collapse-item ant-collapse-no-arrow secondary-panel-container">
                <div className="ant-collapse-header">
                  <p className="ant-collapse-header-text" style={{margin: 0}}>
                    <span className="tc-item-number">8.</span> Usted también es notificado, reconoce y acepta los
                    siguientes términos y condiciones generales:
                  </p>
                </div>

                <Collapse
                  expandIcon={() => (<CkIcon name="panel-expand" fill="#D5000A"/>)}
                  ghost
                  expandIconPosition="end"
                  className="secondary-tc-collapse"
                >
                  <Panel key={20} header="A. Términos específicos de las aplicaciones integradas a Apple">
                    <p>
                      En adición a las demás disposiciones de este acuerdo, los siguientes términos y condiciones se
                      aplican a
                      las aplicaciones de software puestas a disposición por parte de ExxonMobil que se busque sean
                      operadas
                      en relación con productos puestos a disposición comercialmente por Apple Inc. ("<span
                      className="bold">Apple</span>") (cada una, una "<span
                      className="bold">aplicación habilitada para Apple</span>"):
                    </p>

                    <ol>
                      <li>
                        Usted y ExxonMobil reconocen que este acuerdo se celebra entre usted y ExxonMobil, y no con
                        Apple, y
                        ExxonMobil, no Apple, es el único responsable de la aplicación habilitada para Apple y del
                        contenido
                        de la misma.
                      </li>
                      <li>
                        Usted no puede utilizar la aplicación habilitada para Apple en forma tal que infrinja
                        cualquiera
                        de
                        los términos y condiciones de Apple que puedan ser aplicables en la jurisdicción en la que se
                        obtuvo
                        la aplicación CarKer; los términos y condiciones de Apple se pueden encontrar en el siguiente
                        enlace: <a
                        href="https://www.apple.com/legal/internet-services/itunes/ww/"
                        id="TYC001"
                        onClick={handleLinkClick}
                        rel="noopener noreferrer"
                        target="_blank">https://www.apple.com/legal/internet-services/itunes/ww/.</a>
                      </li>
                      <li>
                        Usted reconoce que ha tenido la oportunidad de revisar las "reglas de uso" y cualquier otro
                        término y
                        condición establecido para las aplicaciones con Licencia en los términos y condiciones de
                        iTunes
                        Store, la iTunes Store, Mac App Store, App Store y los términos de venta del iBookstore, los
                        términos
                        y condiciones del Mac App Store, App Store y iBookstore, la política de privacidad, los
                        términos
                        y
                        condiciones del Game Center y cualesquier otros términos y condiciones de Apple que pueda ser
                        aplicables en la jurisdicción en la que se obtuvo la aplicación CarKer; los términos y
                        condiciones de
                        Apple se pueden encontrar en el siguiente enlace: <a
                        href="https://www.apple.com/legal/internet-services/itunes/ww/"
                        id="TYC002"
                        onClick={handleLinkClick}
                        rel="noopener noreferrer"
                        target="_blank">https://www.apple.com/legal/internet-services/itunes/ww/.</a>
                      </li>
                      <li>
                        La licencia que se le otorga para la aplicación habilitada para Apple se limita a una licencia
                        no
                        transferible para usar la aplicación habilitada para Apple en cualquier iPhone, iPad o iPod
                        touch que
                        sea de su propiedad o esté bajo su control según sea permitido por las reglas de uso
                        establecidas en
                        los términos y condiciones de iTunes Store.
                      </li>
                      <li>
                        Usted y ExxonMobil reconocen que Apple no tiene obligación alguna de proporcionar
                        mantenimiento
                        y
                        servicio de soporte con respecto a la aplicación habilitada para Apple.
                      </li>
                      <li>
                        Apple no es responsable de ninguna garantía de producto, ya sea expresa o implícita por ley.
                        En
                        caso
                        de que la aplicación Habilitada para Apple no cumpla con cualquier garantía aplicable, usted
                        podrá
                        notificar a Apple, y Apple le reembolsará el precio de compra de la aplicación habilitada para
                        Apple;
                        y, en la medida máxima permitida por la ley aplicable, Apple no otorga alguna otra obligación
                        de
                        garantía con respecto a la aplicación habilitada para Apple, y cualquier otra reclamación,
                        pérdida,
                        responsabilidad, daño, costo o gasto atribuible a cualquier incumplimiento de cumplir con
                        cualquier
                        garantía será responsabilidad exclusiva de ExxonMobil, en la medida en que dicha garantía o
                        responsabilidad no pueda ser renunciada bajo la ley aplicable.
                      </li>
                      <li>
                        Usted y ExxonMobil reconocen que ExxonMobil, no Apple, es responsable de atender cualquier
                        reclamación
                        presentada por usted o cualquier tercero relacionada con la aplicación habilitada para Apple o
                        su
                        posesión y/o uso de esa aplicación habilitada para Apple, incluyendo sin limitar: (i) reclamos
                        de
                        responsabilidad por productos; (ii) cualquier reclamo en el sentido que la aplicación
                        habilitada
                        para
                        Apple no cumple con cualquier requisito legal o regulatorio aplicable; y (iii) reclamos que
                        surjan de
                        leyes de protección al consumidor o una legislación similar.
                      </li>
                      <li>
                        Usted y ExxonMobil reconocen que, en caso de que un tercero reclame que la aplicación
                        habilitada
                        para
                        Apple o la posesión y uso por parte del usuario final de esa aplicación habilitada para Apple
                        infringe
                        los derechos de propiedad intelectual de ese tercero, ExxonMobil, no Apple, será el único
                        responsable
                        de la investigación, defensa, indemnización y descarga de cualquier reclamo por infracción de
                        propiedad intelectual.
                      </li>
                      <li>
                        Usted declara y garantiza que (i) no se encuentra en un país que está sujeto a un embargo
                        gubernamental en la jurisdicción en la que se obtuvo la aplicación CarKer, o que ha sido
                        designado
                        como un país que "apoya el terrorismo"; y (ii) usted no aparece en ninguna lista gubernamental
                        de
                        partes prohibidas o restringidas.
                      </li>
                      <li>
                        Usted y ExxonMobil reconocen y aceptan que Apple, y las subsidiarias de Apple, son terceros
                        beneficiarios de este acuerdo con respecto a la aplicación habilitada para Apple, y que, a su
                        aceptación de este acuerdo, Apple tendrá el derecho (y se considerará que ha aceptado el
                        derecho) a
                        exigir el cumplimiento de este acuerdo en su contra con respecto a la aplicación habilitada
                        para
                        Apple
                        como tercero beneficiario del mismo.
                      </li>
                    </ol>
                  </Panel>
                  <Panel key={21} header="B. Términos específicos de las aplicaciones integradas de Google">
                    <p>
                      En adición a las demás disposiciones de este Acuerdo, los siguientes términos y condiciones son
                      aplicables las aplicaciones de software puestas a disposición por ExxonMobil que se busca sean
                      operadas
                      en relación con productos puestos a disposición comercialmente por Google LLC ("Google") a
                      través
                      de
                      Google Play Store (cada una, una "aplicación habilitada para Google"):
                    </p>

                    <ol>
                      <li>
                        Usted y ExxonMobil reconocen que este acuerdo se celebra entre usted y ExxonMobil, y no con
                        Google, y
                        que ExxonMobil, no Google, es el único responsable de la aplicación habilitada para Google y
                        del
                        contenido de la misma.
                      </li>
                      <li>
                        No puede utilizar la aplicación habilitada para Google en forma tal que infrinja cualquiera de
                        los
                        términos de servicio de Google que puedan ser aplicables en la jurisdicción en la que se
                        obtuvo
                        la
                        aplicación CarKer; los términos de servicio de Google se pueden encontrar en la siguiente URL:
                        <a style={{textDecoration: "underline"}}
                          href="https://play.google.com/intl/en_us/about/play-terms.html"
                          id="TYC003"
                          onClick={handleLinkClick}
                          rel="noopener noreferrer"
                          target="_blank">https://play.google.com/intl/en_us/about/play-terms.html.</a>
                      </li>
                      <li>
                        Usted reconoce que ha tenido la oportunidad de revisar los "términos de servicio" y cualquier
                        otro
                        término y condición establecido para las aplicaciones con licencia en los términos y
                        condiciones
                        de
                        Google Play Store y cualquier otro término y condición de Google que pueda ser aplicable en la
                        jurisdicción en la que se obtuvo la aplicación CarKer.
                      </li>
                    </ol>

                  </Panel>

                  <Panel key={22} header="C. Liberación de responsabilidad y garantía">
                    <p>
                      Usted reconoce expresamente que el uso de la aplicación CarKer es bajo su propio riesgo y que
                      todo
                      el
                      riesgo en cuanto a la calidad satisfactoria, rendimiento, precisión y esfuerzo es asumido por
                      usted.
                      Además, usted entiende que cualquier pérdida de datos o daño a su sistema de cómputo que
                      experimente
                      utilizando la aplicación CarKer es bajo su propio riesgo. En la medida máxima permitida por la
                      ley
                      aplicable, la aplicación CarKer y los servicios realizados o proporcionados por la aplicación
                      CarKer
                      (los "servicios") se proporcionan "como son" y "como estén disponibles", con todas las fallas y
                      sin
                      garantía de ningún tipo, y se sujetan a cambios en cualquier momento sin notificación alguna.
                    </p>

                    <p>
                      ExxonMobil en este acto renuncia a todas las garantías y condiciones con respecto a la
                      aplicación
                      CarKer
                      y a cualquier servicio, ya sea expresa, implícita o legal, incluyendo sin limitar las garantías
                      y/o
                      condiciones implícitas de comerciabilidad, de calidad satisfactoria, de idoneidad para un
                      propósito
                      específico, de precisión, de uso ininterrumpido y de no violación a derechos de terceros.
                      ExxonMobil no
                      otorga garantía contra la interrupción del uso de la aplicación CarKer, que las funciones
                      contenidas en,
                      o los servicios, cumplirán con sus requisitos, que el funcionamiento de la aplicación CarKer o
                      los
                      servicios no serán interrumpidos, serán seguros o libres de errores, o que los defectos en la
                      aplicación
                      CarKer o servicios serán corregidos. Ninguna informacion o recomendación oral o escrita
                      proporcionada
                      por ExxonMobil o sus representantes autorizados constituirá una garantía. Además, no todas las
                      funciones
                      y características establecidas en este acuerdo, incluyendo sin limitar a la funcionalidad de
                      juego,
                      estarán necesariamente disponibles en la versión inicial (o cualquier versión futura) de la
                      aplicación
                      CarKer, y ninguna mención de dicha funcionalidad o características en el presente deberá ser
                      interpretada como una promesa de proporcionar dicha funcionalidad o características en la
                      aplicación
                      CarKer o en cualquier otro producto ofrecido por ExxonMobil.
                    </p>
                  </Panel>

                  <Panel key={23} header="D. Indemnización">
                    <p>
                      Usted entiende que usted es personalmente responsable de su comportamiento mientras utiliza la
                      aplicación carker. Por lo tanto, acepta indemnizar, defender y mantener en paz y a salvo a
                      ExxonMobil y
                      sus filiales y sus funcionarios, directores, empleados y agentes de y contra cualquier pérdida,
                      daño,
                      responsabilidad, costo o gasto de cualquier tipo (incluyendo honorarios de abogados) en que
                      ExxonMobil
                      pueda incurrir en relación con el uso que usted realice de la aplicación CarKer o su
                      incumplimiento de
                      este acuerdo o de los derechos de cualquier tercero.
                    </p>
                  </Panel>

                  <Panel key={24} header="E. Disponibilidad global / controles de exportación">
                    <p>
                      La aplicación CarKer puede ser controlada y operada por ExxonMobil desde varias oficinas en
                      México, los
                      Estados Unidos de América o la Unión Europea. ExxonMobil no declara que la aplicación CarKer con
                      respecto a su uso o descripción es apropiada o está disponible para uso en otros lugares, y el
                      acceso a
                      la misma desde otros países donde los contenidos son ilegales o sancionados está prohibido. El
                      acceso a
                      o uso de la aplicación CarKer por personas o países sancionados por México, los Estados Unidos
                      de
                      América o la Unión Europea está específicamente prohibido. Aquellos que acceden a la aplicación
                      CarKer,
                      lo hacen por su cuenta y son responsables del cumplimiento con todas las leyes aplicables de
                      México, los
                      Estados Unidos de América o la Unión Europea, según sea el caso. Usted no puede exportar ni
                      re-exportar
                      la aplicación CarKer, información o materiales a los que se acceda o se describan en la
                      aplicación
                      CarKer, excepto en pleno cumplimiento con todas las leyes y regulaciones de México, los Estados
                      Unidos
                      de América y la Unión Europea, según sea el caso. Particularmente, no podrá exportar ni
                      re-exportar a (o
                      a un nacional o residente de) algún país al que los Estados Unidos de América o la Unión Europea
                      embarguen o sancionen bienes, servicios, o tecnología, a cualquier persona o entidad de la Lista
                      de
                      Nacionales Especialmente Designados y personas bloqueadas por el Departamento del Tesoro de los
                      Estados
                      Unidos de América, Oficina de Administración de Exportaciones del Departamento de Comercio de
                      Estados
                      Unidos de América, a la lista de Personas Denegadas de la Oficina de Administración de
                      Exportaciones de
                      los Estados Unidos de América, a la Tabla de Órdenes de Denegación del Departamento de Comercio
                      de
                      los
                      Estados Unidos de América y a cualquier medida o sanción restrictiva de la Unión Europea y los
                      Estados
                      miembros de la Unión Europea. Además, usted es responsable de cumplir con cualquier ley local en
                      su país
                      que pueda afectar su derecho a importar, exportar o utilizar la aplicación CarKer.
                    </p>
                  </Panel>

                  <Panel key={25} header="F. Política relativa a niños">
                    <p>
                      La aplicación CarKer no está destinada a niños menores de 15 años y no recabamos
                      intencionalmente
                      cualquier información personal de dichos niños. El uso de la aplicación CarKer está prohibido
                      para
                      niños
                      menores de 15 años, y los niños menores de 15 años no deben usar la aplicación CarKer en ningún
                      momento.
                      Si tiene entre 15 y 18 años de edad deberá obtener la autorización de sus padres o tutores
                      legales
                      antes
                      de usar la aplicación CarKer. ExxonMobil cumple con la Ley Federal de Protección de Datos
                      Personales en
                      Posesión de Particulares y otras leyes, según corresponda. Aunque la aplicación CarKer puede
                      contener
                      información o proporcionar servicios que puedan ser de interés para niños, la aplicación CarKer
                      no
                      está
                      dirigida a niños y ExxonMobil no recaba ni solicita intencionalmente información personal de
                      niños
                      menores de 15 años. En el caso de que ExxonMobil se entere de que ExxonMobil ha recabado
                      inadvertidamente información personal de niños menores de 15 años, ExxonMobil tomará las medidas
                      razonables para eliminar inmediatamente dicha información de nuestros registros.
                    </p>
                  </Panel>

                  <Panel key={26} header="G. Política de privacidad">
                    <p>
                      La información que ExxonMobil puede recopilar de usted durante su uso de la aplicación CarKer
                      está
                      sujeta a nuestra política de privacidad (que puede consultar <a
                      href="https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing"
                      id="TYC004"
                      onClick={handleLinkClick}
                      rel="noopener noreferrer"
                      target="_blank">
                      aquí</a>) u otros avisos adicionales,
                      según
                      corresponda. El uso que realice de la aplicación CarKer puede estar condicionado a su
                      reconocimiento y
                      aceptación de la política de privacidad o los aviso(s) aplicable(s).
                    </p>
                  </Panel>

                  <Panel key={27} header="H. Disputas, jurisdicción y ley aplicable">
                    <p>
                      El acuerdo se rige e interpreta de conformidad con las leyes federales del México, excluyendo
                      sus
                      normas
                      sobre conflictos de leyes y sin referencia a la Convención de las Naciones Unidas sobre los
                      Contratos de
                      Compraventa Internacional de Mercaderías. En caso de cualquier acción, demanda o procedimiento
                      que
                      surja
                      de o de alguna manera esté relacionada con este acuerdo, usted y ExxonMobil designan a los
                      tribunales
                      competentes en la Ciudad de México, México, para la resolución exclusiva de esa disputa y se
                      someten a
                      la jurisdicción de ese tribunal, renunciando en este acto a cualquier otra jurisdicción que
                      pudiera
                      corresponderles por su domicilio presente o futuro o por cualquier otra razón.
                    </p>
                  </Panel>

                  <Panel key={28} header="I. Retención de registros">
                    <p>
                      ExxonMobil México retiene los datos personales todo el tiempo que sea necesario para cumplir con
                      los fines recopilados, para ejercer sus derechos legales y para asegurar el cumplimiento de la
                      legislacioón aplicable. Usted como titular de los datos personales podrá <a target="_blank"
                                                                                                  style={{textDecoration: "underline"}}
                                                                                                  onClick={() => {
                                                                                                    setShowCancelDataRetentionNotice(true);
                                                                                                    onDataRetentionClick();
                                                                                                  }}>solicitar su
                      cancelación</a> cuando ya no sea necesario mantenerlos.
                    </p>

                  </Panel>

                  <Panel key={29} header="J. Otras disposiciones">
                    <p>
                      Este acuerdo es el acuerdo completo entre usted y ExxonMobil. Este acuerdo reemplaza y
                      substituye
                      todos
                      y cada uno de los convenios anteriores o contemporáneos entre usted y ExxonMobil en relación con
                      el uso
                      que realice de la aplicación CarKer (incluyendo versiones anteriores de este acuerdo). El hecho
                      de
                      que
                      ExxonMobil no ejerza o exija el cumplimiento de cualquier derecho o disposición de este acuerdo
                      no
                      se
                      considerará como una renuncia a dicho derecho o disposición. Este acuerdo opera en la máxima
                      medida
                      permitida por ley. Si alguna disposición de este acuerdo es declarada ilegal, nula o inexigible
                      por un
                      tribunal con base en cualquier resolución escrita, se considerará que esa disposición no es
                      parte
                      de
                      este acuerdo y no afectará la validez y exigibilidad de las otras disposiciones. Podemos cambiar
                      este
                      acuerdo en cualquier momento y su uso continuo de la aplicación CarKer después de cualquier
                      cambio
                      que
                      realicemos significará que usted estuvo de acuerdo con los cambios. La notificación puede
                      hacerse
                      mostrando avisos o enlaces a avisos a usted generalmente en la aplicación CarKer.
                    </p>

                    <p className="bold">
                      EN CASO DE QUE USTED NO ACEPTE ALGUNO DE ESTOS TÉRMINOS, NO PODRÁ ACCEDER NI UTILIZAR LA
                      APLICACIÓN
                      CARKER PARA NINGÚN OBJETO.
                    </p>

                    <p className="bold">
                      AL ACEPTAR ESTOS TÉRMINOS DE USO, USTED ACEPTA QUE CUALQUIER RECLAMO O DISPUTA DEBE PRESENTARSE
                      ÚNICAMENTE EN UNA BASE INDIVIDUAL Y NO COMO DEMANDANTE O MIEMBRO DE CLASE EN CUALQUIER PRESUNTA
                      CLASE.
                    </p>
                  </Panel>

                  <Panel key={30} header="K. Otros avisos legales">
                    <p>
                      La aplicación CarKer puede utilizar o en otra manera incorporar una variedad de productos de
                      software
                      proporcionados por proveedores externos, muchos de los cuales pueden requerir que ExxonMobil
                      exprese
                      ciertas atribuciones y términos. Estas atribuciones y términos son parte de este acuerdo como si
                      se
                      incorporarán completamente al mismo.
                    </p>
                  </Panel>

                  <Panel key={31} header="L. Uso seguro de la aplicación CarKer">
                    <p>
                      Si tiene alguna pregunta o duda acerca de la aplicación CarKer, puede ponerse en contacto con
                      nosotros
                      en: <a 
                      href="mailto:soporte@carker.com.mx"
                      id="TYC005"
                      onClick={handleLinkClick}
                      rel="noopener noreferrer"
                      target="_blank">soporte@carker.com.mx</a>
                    </p>

                  </Panel>
                </Collapse>
              </div>
            </Collapse>
          </Fragment>
        )
      }
    </div>
  );
};
